<template>
  <div class="collection-cont">
    <h5>{{ $t("wish.wish_title") }}</h5>
    <div class="pucontainer">
      <div class="likeList">
        <div class="lItem" v-for="(item, i) in likelist" :key="i">
          <div class="ltop">
            <template v-if="item.sprice=='0.00' && item.stock == 0">
              <q-img :src="item.photo" :ratio="6 / 8" @click="$q.notify($t('common.no_goods'))" />
            </template>
            <template v-else>
              <router-link :to="'/goods?guid=' + item.cguid">
                <q-img :src="item.photo" :ratio="6 / 8" />
              </router-link>
            </template>
            <!-- 关闭 -->
            <span class="remove-btn extend-click" @click.stop="deletWish(item.wguid, i)">
              <i class="iconfont icon-guanbi"></i>
            </span>
            <span class="loupe iconfont icon-sousuo" @click.stop="loupeHandle(item)"></span>
          </div>
          <div class="lcenter">
            <div class="brand blod no-wrap-txt">
              {{ item.generalattributename }}
            </div>
            <div class="title no-wrap-txt">
              {{ $i18n.locale == "en" ? item.titleen : item.title }}
            </div>
            <template v-if="item.sprice!='0.00'">
              <div class="price line-through" v-if="item.isdiscount == 1">
                ￥{{ item.oprice }}
              </div>
              <div class="price">￥{{ item.sprice }}</div>
            </template>
            <div class="size" v-show="item.size != '01'">{{ item.size }}</div>
          </div>
          <div class="lbottom">
            <q-btn v-if="item.stock && item.isuppershelf == 1" class="full-width" color="black" :label="$t('wish.add_cart')" @click="addCart(item)" />
            <q-btn v-else-if="item.isuppershelf == 0" class="full-width" unelevated style="background: #e6e6e6; color: #a8a8a8" :label="$t('wish.withdrawn')" />
            <q-btn v-else class="full-width" unelevated style="background: #e6e6e6; color: #a8a8a8" :label="$t('wish.no_stock')" />
          </div>
        </div>
      </div>
    </div>
    <q-dialog v-model="show">
      <q-card style="width: 380px">
        <q-card-section>
          <q-select v-model="colors" :options="colorOptions" :option-label="$i18n.locale == 'en' ? 'colorsen' : 'colors'" :label="$t('wish.color')" @update:model-value="colorChange(colors)" />
          <q-select v-show="size.size != '01'" v-model="size" option-label="size" :options="sizeOptions" :label="$t('wish.size')" />
          <p class="select-price">
            {{ $t("wish.price")
            }}<span v-if="size.isDiscount == 1" class="line-through">￥{{ size.oPrice }}</span>￥{{ size.Price }}
          </p>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat :label="$t('wish.cancel')" color="primary" v-close-popup />
          <q-btn flat :label="$t('wish.ok')" color="primary" @click="onConfirm" :loading="cartLoading" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <m-empty v-if="!likelist.length" :title="$t('wish.no_wish')"></m-empty>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { cartApi } from '@/api/goods'
import { wish } from '@/api/my'
export default {
  components: {
    mEmpty
  },
  data () {
    return {
      likelist: [],
      show: false,
      colorOptions: '',
      sizeOptions: '',
      colors: '',
      size: '',
      cguid: '',
      cartLoading: false
    }
  },
  methods: {
    async getWish () {
      const res = await wish({
        type: 0
      })
      if (res.status === 1) {
        res.list.forEach((item) => {
          item.size = item.size.split(',').join(' / ')
        })
        this.likelist = res.list
      }
    },
    // 去除心愿
    deletWish (guid, index) {
      this.$q
        .dialog({
          message: this.$t('wish.confirm_delete'),
          cancel: true
        })
        .onOk(async () => {
          const res = await wish({
            type: 3,
            mark: guid
          })
          if (res.status === 1) {
            this.likelist.splice(index, 1)
          } else {
            this.$q.notify(
              this.$i18n.locale === 'en' ? res.contenten : res.content
            )
          }
        })
    },
    async onConfirm () {
      this.cartLoading = true
      const res = await cartApi({
        type: 1,
        mark: this.cguid,
        sguid: this.size.guid,
        quantity: 1
      })
      if (res.status === 1) {
        this.show = false
        this.$q.notify(this.$t('wish.add_success'))
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      this.cartLoading = false
    },
    // 添加购物车
    addCart (item) {
      this.colorOptions = item.salesspecifications
      this.cguid = item.cguid
      this.colors = this.$i18n.locale === 'en' ? this.colorOptions[0].colorsen : this.colorOptions[0].colors
      this.sizeOptions = this.colorOptions[0].salesspecifications
      this.size = this.colorOptions[0].salesspecifications[0]
      this.show = true
    },
    // 颜色变化
    colorChange (item) {
      this.sizeOptions = item.salesspecifications
      this.size = item.salesspecifications[0]
    },
    loupeHandle (item) {
      this.$viewerApi({
        images: [item.photo]
      })
    }
  },
  mounted () {
    this.getWish()
  }
}
</script>

<style lang="scss" scoped>
.select-price {
  margin-top: 10px;
  margin-bottom: 0;
}

.collection-cont {
  h5 {
    text-align: center;
  }
  .pucontainer {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    .likeList {
      display: flex;
      flex-wrap: wrap;
      .lItem {
        width: 25%;
        padding: 0 5px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        .ltop {
          position: relative;
          cursor: pointer;
          .remove-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
          }
          .loupe {
            position: absolute;
            left: 10px;
            top: 10px;
            font-size: 20px;
          }
        }
        .lcenter {
          width: 100%;
          overflow: hidden;
          text-align: center;
          flex: 1;
          .brand {
            font-size: 16px;
            font-weight: bold;
            margin-top: 16px;
          }
          .price {
            font-weight: bold;
            font-size: 16px;
          }
          .size {
            word-wrap: break-word;
          }
        }
        .lbottom {
          width: 100%;
          max-width: 210px;
          margin: 0 auto;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>
